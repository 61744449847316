.dropzone--croptool {
    position: relative;
    min-height: 60vh;
    border: 2px solid rgba(0,0,0,.1);


    .dz-message {
        margin: -17px 0 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
    }

    .dz-preview {
    	display:none !important;
    }
}

#cropper__tool {
	display: none;

	.crop__actions {

		#currentWidth,
		#currentHeight {
			margin-top: 3px;
		}
	}

	#croppableImage {
		max-width: 100%;
	}	
}